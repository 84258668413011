// blogs drawer
const blogsMenuDrawer = document.getElementById('blogs-drawer');
const blogsMenuButton = document.getElementById('blogs-menu-button');

const closeBlogsMenuButton = document.getElementById('close-blogs-menu');
const blogsMenuCloseAll = document.getElementById('close-all-blogs-menus');

if(blogsMenuDrawer) {
  blogsMenuButton.addEventListener('click', () => blogsMenuDrawer.show());
  closeBlogsMenuButton.addEventListener('click', () => blogsMenuDrawer.hide());
  blogsMenuCloseAll.addEventListener('click', () => {
    blogsMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}

// newsletter drawer
const newsletterMenuDrawer = document.getElementById('newsletter-drawer');
const newsletterMenuButton = document.getElementById('newsletter-menu-button');

const closeNewsletterMenuButton = document.getElementById(
  'close-newsletter-menu'
);
const newsletterMenuCloseAll = document.getElementById(
  'close-all-newsletter-menus'
);

if(newsletterMenuDrawer) {
  newsletterMenuButton.addEventListener('click', () =>
    newsletterMenuDrawer.show()
  );
  closeNewsletterMenuButton.addEventListener('click', () =>
    newsletterMenuDrawer.hide()
  );
  newsletterMenuCloseAll.addEventListener('click', () => {
    newsletterMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}

// videos drawer
const videosMenuDrawer = document.getElementById('videos-drawer');
const videosMenuButton = document.getElementById('videos-menu-button');

const closeVideosMenuButton = document.getElementById('close-videos-menu');
const videosMenuCloseAll = document.getElementById('close-all-videos-menus');

if(videosMenuDrawer) {
  videosMenuButton.addEventListener('click', () => videosMenuDrawer.show());
  closeVideosMenuButton.addEventListener('click', () => videosMenuDrawer.hide());
  videosMenuCloseAll.addEventListener('click', () => {
    videosMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}

// podcasts drawer
const podcastsMenuDrawer = document.getElementById('podcasts-drawer');
const podcastsMenuButton = document.getElementById('podcasts-menu-button');

const closepodcastsMenuButton = document.getElementById('close-podcasts-menu');
const podcastsMenuCloseAll = document.getElementById(
  'close-all-podcasts-menus'
);

if(podcastsMenuDrawer) {
  podcastsMenuButton.addEventListener('click', () => podcastsMenuDrawer.show());
  closepodcastsMenuButton.addEventListener('click', () =>
    podcastsMenuDrawer.hide()
  );
  podcastsMenuCloseAll.addEventListener('click', () => {
    podcastsMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}

// resources drawer
const resourcesMenuDrawer = document.getElementById('resources-drawer');
const resourcesMenuButton = document.getElementById('resources-menu-button');

const closeresourcesMenuButton = document.getElementById(
  'close-resources-menu'
);
const resourcesMenuCloseAll = document.getElementById(
  'close-all-resources-menus'
);

if(resourcesMenuDrawer) {
  resourcesMenuButton.addEventListener('click', () => resourcesMenuDrawer.show());
  closeresourcesMenuButton.addEventListener('click', () =>
    resourcesMenuDrawer.hide()
  );
  resourcesMenuCloseAll.addEventListener('click', () => {
    resourcesMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}
// products drawer
const productMenuDrawer = document.getElementById('product-drawer');
const productMenuButton = document.getElementById('product-menu-button');

const closeproductMenuButton = document.getElementById('close-product-menu');
const productMenuCloseAll = document.getElementById('close-all-product-menus');

if(productMenuDrawer) {
  productMenuButton.addEventListener('click', () => productMenuDrawer.show());
  closeproductMenuButton.addEventListener('click', () =>
    productMenuDrawer.hide()
  );
  productMenuCloseAll.addEventListener('click', () => {
    productMenuDrawer.hide();
    mobileMenu.hide(); // also close the mobile menu
  });
}

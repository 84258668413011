const mobileMenu = document.querySelector('.mobile-menu');
const openButton = document.getElementById('mobile-menu-button');
const closeButton = document.getElementById('close-mobile-menu');

openButton.addEventListener('click', () => mobileMenu.show());
closeButton.addEventListener('click', () => mobileMenu.hide());

// software drawer
const softwareDrawer = document.getElementById('software-drawer');
const softwareButton = document.getElementById('software-menu-button');

const closeSoftwareButton = document.getElementById('close-software-menu');
const softwareCloseAll = document.getElementById('close-all-sotware-menus');

softwareButton?.addEventListener('click', () => softwareDrawer.show());
closeSoftwareButton?.addEventListener('click', () => softwareDrawer.hide());
softwareCloseAll?.addEventListener('click', () => {
  softwareDrawer.hide();
  mobileMenu.hide(); // also close the mobile menu
});

// resources drawer
const resourcesDrawer = document.getElementById('resources-drawer');
const resourcesButton = document.getElementById('resources-menu-button');

const closeResourcesButton = document.getElementById('close-resources-menu');
const resourcesCloseAll = document.getElementById('close-all-resources-menus');

resourcesButton?.addEventListener('click', () => resourcesDrawer.show());
closeResourcesButton?.addEventListener('click', () => resourcesDrawer.hide());
resourcesCloseAll?.addEventListener('click', () => {
  resourcesDrawer.hide();
  mobileMenu.hide(); // also close the mobile menu
});

// about drawer
const aboutDrawer = document.getElementById('about-drawer');
const aboutButton = document.getElementById('about-menu-button');

const closeAboutButton = document.getElementById('close-about-menu');
const aboutCloseAll = document.getElementById('close-all-about-menus');

aboutButton?.addEventListener('click', () => aboutDrawer.show());
closeAboutButton?.addEventListener('click', () => aboutDrawer.hide());
aboutCloseAll?.addEventListener('click', () => {
  aboutDrawer.hide();
  mobileMenu.hide(); // also close the mobile menu
});

// language drawer
const languageDrawer = document.getElementById('language-drawer');
const languageButton = document.getElementById('language-menu-button');

const closeLanguageButton = document.getElementById('close-language-menu');
const languageCloseAll = document.getElementById('close-all-language-menus');

languageButton?.addEventListener('click', () => languageDrawer.show());
closeLanguageButton?.addEventListener('click', () => languageDrawer.hide());
languageCloseAll?.addEventListener('click', () => {
  languageDrawer.hide();
  mobileMenu.hide(); // also close the mobile menu
});

// hide all accordions when one opens
const accordions = document.querySelectorAll('.accordion .accordion-item');
accordions.forEach((accordion) => {
  // hide when shown
  accordion?.addEventListener('sl-show', () => {
    accordions.forEach((a) => {
      if (a !== accordion) {
        a.classList.add('hidden');
      }
    });
  });

  // show when hidden
  accordion?.addEventListener('sl-hide', () => {
    accordions.forEach((a) => {
      if (a !== accordion) {
        a.classList.remove('hidden');
      }
    });
  });
});

const swiper = new Swiper('.swiper', {
  loop: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/********************     ******************* */

const button = document.getElementById('mobile-menu-button');
let rotated = false;

button?.addEventListener('click', () => {
  rotated = !rotated;
  button.style.transform = rotated ? 'rotate(360deg)' : 'rotate(0deg)';
});

// video dialog
const dialog = document.querySelector('.video-dialog');
const openDialogButton = document.getElementById('open-video-dialog');

openDialogButton?.addEventListener('click', () => dialog.show());

/************************************* */
// blog search dialog

const blogSearchButton = document.getElementById('blog-search');


const blogSearchDialog = document.getElementById("blog-search-dialog")
const closeBlogSearchButton = document.querySelector('.blog-search-close');

blogSearchButton?.addEventListener('click', () => blogSearchDialog.show());
closeBlogSearchButton?.addEventListener('click', () => blogSearchDialog.hide());


// forms images dialogs
const formTypeDialogButton = document.querySelector('.form-type-image');
const formTypeDialog = document.querySelector(".form-type-dialog")
formTypeDialogButton?.addEventListener('click', () => formTypeDialog.show());

const formDndDialogButton = document.querySelector('.form-dnd-image');
const formDndDialog = document.querySelector(".form-dnd-dialog")

formDndDialogButton?.addEventListener('click', () => formDndDialog.show());


const formOptionsDialogButton = document.querySelector('.form-options-image');
const formOptionsDialog = document.querySelector(".form-options-dialog")

formOptionsDialogButton?.addEventListener('click', () => formOptionsDialog.show());
                                          
/************************** */
// blogs drawer
const blogsDrawer = document.getElementById('blogs-drawer');
const blogsButton = document.getElementById('blogs-menu-button');

const closeBlogsButton = document.getElementById('close-blogs-menu');
const blogsCloseAll = document.getElementById('close-all-blogs-menus');

blogsButton?.addEventListener('click', () => blogsDrawer.show());
closeBlogsButton?.addEventListener('click', () => blogsDrawer.hide());
blogsCloseAll?.addEventListener('click', () => {
  blogsDrawer.hide();
  mobileMenu.hide(); // also close the mobile menu
});


import "index.scss"
// import "custom.scss"
// import "custom.js"
import "output.scss"
import "blogsMenus.js"
import "script.js"
// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")
